import classNames from 'classnames/bind';
import { AnimatePresence, motion } from 'framer-motion';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useKey } from 'react-use';
import styles from './NewsletterForm.module.scss';

const cx = classNames.bind(styles);

const variants = {
  out: { opacity: 0 },
  in: { opacity: 1 },
};

interface NewsletterFormProps {
  narrow?: boolean;
}

const NewsletterForm: React.FC<NewsletterFormProps> = ({ narrow }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [pending, setPending] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  useKey('Escape', () => setPopupOpen(false));

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError('');
    setPending(true);

    const { result, msg } = await addToMailchimp(email);

    setPending(false);

    if (result === 'error') {
      setError(msg);
    } else {
      trackCustomEvent({
        category: 'Private Beta',
        action: 'Email List Sign Up',
        label: 'Sign Up',
      });

      setPopupOpen(true);
      setEmail('');
    }
  };

  return (
    <>
      <div className={cx('newsletter-form', { narrow })}>
        <div className="container">
          {!narrow && (
            <div className={cx('cta-wrapper')}>
              <h3>Early Access Program</h3>
              <p>Sendeo is currently in private beta but you can use the form below to register for early access.</p>
            </div>
          )}
          <form className={cx('form')} action="/" method="post" onSubmit={handleSubmit}>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              spellCheck={false}
              autoComplete="email"
              required
              placeholder="Business email"
              disabled={pending}
            />
            <button type="submit" disabled={pending}>
              Request Early Access {pending && <span>Processing...</span>}
            </button>
          </form>
          {error && <p className={cx('error')} dangerouslySetInnerHTML={{ __html: error }} />}
        </div>
      </div>
      <AnimatePresence>
        {popupOpen && (
          <motion.div initial="out" animate="in" exit="out" variants={variants} className={cx('popup-container')}>
            <div className={cx('popup')}>
              <OutsideClickHandler onOutsideClick={() => setPopupOpen(false)}>
                <button className={cx('popup-close')} onClick={() => setPopupOpen(false)}>
                  Close
                </button>

                <h3>We're excited to have you!</h3>
                <p>
                  You're now on the <em>early-access program</em> list. We're releasing the app invites periodically so
                  watch out for it in your inbox!
                </p>
                <p>
                  You can jump the queue and <strong>spread the word</strong> at the same time (we're a small,
                  self-funded startup after all) by tweeting using the button below.
                </p>

                <a
                  href="https://twitter.com/intent/tweet?text=I've just signed up for early access on Sendeo, a modern email marketing and automation platform. Register for free on&amp;url=https://sendeo.co&amp;related=sendeomail"
                  target="_blank"
                  className={cx('popup-cta')}
                >
                  Tweet about Sendeo
                </a>
                <div className={cx('popup-info')}>(will open a tweet composer, you can edit before tweeting)</div>
              </OutsideClickHandler>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default NewsletterForm;
